<template>
  <div class="w-100 template-unit-container">
    <div class="d-flex align-center">
      <v-text-field
        class="mr-2"
        v-model="unit.name"
        :label="
          $localizationService.localize(
            'drp_import.stepper.template.unit.param.name'
          )
        "
        :disabled="mode == VIEW_MODE || pLoading"
      ></v-text-field>
      <v-text-field
        class="mr-2"
        v-model="unit.regex"
        :label="
          $localizationService.localize(
            'drp_import.stepper.template.unit.param.regex'
          )
        "
        :disabled="mode == VIEW_MODE || pLoading"
      ></v-text-field>
      <v-checkbox
        v-model="unit.optional"
        :label="
          $localizationService.localize(
            'drp_import.stepper.template.unit.param.optional'
          )
        "
        :disabled="mode == VIEW_MODE || pLoading"
      ></v-checkbox>

      <div v-if="mode == EDIT_MODE">
        <v-icon
          class="ml-4"
          color="green"
          @click="updateUnit()"
          :disabled="pLoading || !unit.name || !unit.regex"
          >mdi-check</v-icon
        >
        <v-icon
          :disabled="pLoading"
          class="ml-2 mr-2"
          color="red"
          @click="close()"
          >mdi-close</v-icon
        >
      </div>

      <div v-if="mode == VIEW_MODE">
        <v-btn class="ml-2" icon @click="enableEditMode()" :disabled="pLoading">
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="red" @click="deleteUnit()" :disabled="pLoading">
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </div>
    </div>
    <ConfirmationDialog ref="confirmationDialog" />
  </div>
</template>

<script>
import Vue from "vue";

const locService = Vue.prototype.$localizationService;

const EDIT_MODE = "edit";
const VIEW_MODE = "view";

export default {
  name: "TemplateUnit",

  props: {
    pUnit: {
      type: Object,
      default: Object,
    },
    pLoading: Boolean,
    pUnitInEditMode: Boolean,
  },

  components: {
    ConfirmationDialog: () => import("../../ConfirmationDialog.vue"),
  },

  data() {
    return {
      VIEW_MODE,
      EDIT_MODE,

      unit: { ...this.pUnit },

      mode: undefined,
    };
  },

  methods: {
    updateUnit() {
      this.closeEditMode();

      this.$emit("onChangeUnit", this.unit);
    },

    createNewUnit() {
      this.unit = {
        name: "",
        regex: "",
        optional: false,
      };
      this.mode = EDIT_MODE;
    },

    enableEditMode() {
      this.mode = EDIT_MODE;
      this.$emit("onChangeUnitMode", this.unit);
    },

    close() {
      if (this.pUnit) {
        this.unit.name = this.pUnit.name;
        this.unit.regex = this.pUnit.regex;
        this.unit.optional = this.pUnit.optional;
      }

      this.closeEditMode();
    },

    closeEditMode() {
      if (this.unit.id) {
        this.mode = VIEW_MODE;
        this.$emit("onChangeUnitMode", undefined);
      } else {
        this.$emit("onCloseEditMode");
      }
    },

    deleteUnit() {
      this.$refs.confirmationDialog.openDialog({
        header: locService.localize(
          "drp_import.stepper.template.unit.dialog.delete.title",
        ),
        text: locService.localize(
          "drp_import.stepper.template.unit.dialog.delete.text",
          [this.unit.name],
        ),
        submitText: locService.localize("btn.delete"),
        submitColor: "red",
        onSubmit: async () => this.$emit("onDeleteUnit", this.unit),
      });
    },
  },

  mounted() {
    this.mode = this.pUnitInEditMode ? EDIT_MODE : VIEW_MODE;
  },
};
</script>

<style lang="scss">
.template-unit-container {
  .v-input--is-disabled input {
    color: var(--v-themeTextColor-base) !important;
  }
}
</style>
